import axios from 'axios';
import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
`;

const ProductCard = styled.div`
  text-align: center;
  text-decoration: none;
  color: black;
  font-family: 'Raleway', sans-serif;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const ProductLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 18px;
    margin-bottom: 5px;
  }

  p {
    font-size: 16px;
    color: #666;
  }
`;

const AddToCartButton = styled.button`
  background: #333;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  border-radius: 4px;
  margin-top: 10px;

  &:hover {
    background: #555;
  }
`;

function ProductGrid({ addToCart }) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    axios.get('/.netlify/functions/fetch-products-square')
      .then(response => {
        console.log(response.data);
        setProducts(response.data.products);
      })
      .catch(error => {
        console.error('Error fetching products:', error);
      });
  }, []);

  return (
    <GridContainer>
      {products.length > 0 ? (
        products.map((product) => (
          <ProductCard key={product.id}>
            <ProductLink to={`/products/${product.id}`}>
              <img src={product.images.length > 0 ? product.images[0] : 'placeholder-image-url'} alt={product.name} />
              <h2>{product.name}</h2>
              <p>{(product.price.unit_amount / 100).toFixed(2)} {product.price.currency.toUpperCase()}</p>
            </ProductLink>
            <AddToCartButton onClick={() => addToCart(product)}>Add to Cart</AddToCartButton>
          </ProductCard>
        ))
      ) : (
        <p>Loading products...</p>
      )}
    </GridContainer>

    // <GridContainer>
    //   {products.length > 0 ? (
    //     products.map(product => (
    //       <ProductCard key={product.id}>
    //         <ProductLink to={`/products/${product.id}`}>
    //           <img src={product.images.length > 0 ? product.images[0] : 'placeholder-image-url'} alt={product.name} />
    //           <h2>{product.name}</h2>
    //           <p>{(product.price.unit_amount / 100).toFixed(2)} {product.price.currency.toUpperCase()}</p>
    //         </ProductLink>
    //         <AddToCartButton onClick={() => addToCart(product)}>Add to Cart</AddToCartButton>
    //       </ProductCard>
    //     ))
    //   ) : (
    //     <p>Loading products...</p>
    //   )}
    // </GridContainer>
  );
}

export default ProductGrid;


// import axios from 'axios';
// import { useState, useEffect } from 'react';
// import styled from '@emotion/styled';
// import { Link } from 'react-router-dom';

// const GridContainer = styled.div`
//   display: grid;
//   grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
//   gap: 20px;
//   padding: 20px;
// `;

// const ProductCard = styled.div`
//   text-align: center;
//   text-decoration: none;
//   color: black;
//   font-family: 'Raleway', sans-serif;
//   border: 1px solid #ddd;
//   padding: 15px;
//   border-radius: 8px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   transition: box-shadow 0.3s ease;

//   &:hover {
//     box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
//   }
// `;

// const ProductLink = styled(Link)`
//   text-decoration: none;
//   color: inherit;

//   img {
//     width: 100%;
//     height: auto;
//     border-radius: 8px;
//     margin-bottom: 10px;
//   }

//   h2 {
//     font-size: 18px;
//     margin-bottom: 5px;
//   }

//   p {
//     font-size: 16px;
//     color: #666;
//   }
// `;

// const AddToCartButton = styled.button`
//   background: #333;
//   color: white;
//   padding: 8px 16px;
//   border: none;
//   cursor: pointer;
//   font-family: 'Raleway', sans-serif;
//   border-radius: 4px;
//   margin-top: 10px;

//   &:hover {
//     background: #555;
//   }
// `;

// function ProductGrid({ addToCart }) {
//   const [products, setProducts] = useState([]);

//   useEffect(() => {
//     axios.get('/.netlify/functions/fetch-products-square')
//       .then(response => {
//         console.log(response.data);
//         setProducts(response.data.products);
//       })
//       .catch(error => {
//         console.error('Error fetching products:', error);
//       });
//   }, []);

//   return (
//     <GridContainer>
//       {products.length > 0 ? (
//         products.map(product => (
//           <ProductCard key={product.id}>
//             <ProductLink to={`/products/${product.id}`}>
//               <img src={product.images[0]} alt={product.name} />
//               <h2>{product.name}</h2>
//               <p>{(product.prices[0].unit_amount / 100).toFixed(2)} {product.prices[0].currency.toUpperCase()}</p>
//             </ProductLink>
//             <AddToCartButton onClick={() => addToCart(product)}>Add to Cart</AddToCartButton>
//           </ProductCard>
//         ))
//       ) : (
//         <p>Loading products...</p>
//       )}
//     </GridContainer>
//   );
// }

// export default ProductGrid;


// import styled from '@emotion/styled';
// import { Link } from 'react-router-dom';

// const GridContainer = styled.div`
//   display: grid;
//   grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
//   gap: 20px;
//   padding: 20px;
// `;

// const ProductCard = styled(Link)`
//   text-align: center;
//   text-decoration: none;
//   color: black;
//   font-family: 'Raleway', sans-serif;

//   img {
//     width: 100%;
//     height: auto;
//   }
// `;

// function ProductGrid() {
//   const products = [
//     { id: 1, name: 'Vintage Dress', image: '/path-to-image-1.jpg', price: '$50' },
//     { id: 2, name: 'Retro Lamp', image: '/path-to-image-2.jpg', price: '$75' },
//     { id: 3, name: 'Classic Barware', image: '/path-to-image-3.jpg', price: '$30' },
//     // Add more products as needed
//   ];

//   return (
//     <GridContainer>
//       {products.map(product => (
//         <ProductCard to={`/products/${product.id}`} key={product.id}>
//           <img src={product.image} alt={product.name} />
//           <h2>{product.name}</h2>
//           <p>{product.price}</p>
//         </ProductCard>
//       ))}
//     </GridContainer>
//   );
// }

// export default ProductGrid;
