import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styled from '@emotion/styled';

const ProductDetailContainer = styled.div`
  display: flex;
  padding: 20px;
`;

const ProductImages = styled.div`
  flex: 1;
`;

const ProductInfo = styled.div`
  flex: 1;
  padding: 20px;
  font-family: 'Raleway', sans-serif;
`;

const MainImage = styled.img`
  width: 100%;
  height: auto;
`;

function ProductDetail() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    axios
      .post('/.netlify/functions/fetch-product-details-square', { id }) // Fetch product by ID
      .then((response) => {
        setProduct(response.data.product);
      })
      .catch((error) => {
        console.error('Error fetching product:', error);
      });
  }, [id]);

  if (!product) {
    return <p>Loading product details...</p>;
  }

  // Access product variations and images
  const variation = product.itemData.variations[0];
  const images = product.imageData || [];

  return (
    <ProductDetailContainer>
      <ProductImages>
        <MainImage
          src={images.length > 0 ? images[0].url : 'placeholder-image-url'}
          alt={product.itemData.name}
        />
        {/* Additional thumbnails can go here */}
      </ProductImages>
      <ProductInfo>
        <h1>{product.itemData.name}</h1>
        <p>{product.itemData.description}</p>
        {variation && (
          <h2>
            {(variation.itemVariationData.priceMoney.amount / 100).toFixed(2)}{' '}
            {variation.itemVariationData.priceMoney.currency.toUpperCase()}
          </h2>
        )}
        <button onClick={() => handleBuyNow(variation.id)}>Buy Now</button>
      </ProductInfo>
    </ProductDetailContainer>
  );
}

// Handle the "Buy Now" button functionality
const handleBuyNow = async (variationId) => {
  // This function would handle redirecting the user to a checkout flow
  // Example with Stripe Checkout flow
  // const stripe = await loadStripe('your-publishable-key-here');

  // const { error } = await stripe.redirectToCheckout({
  //   lineItems: [{ price: variationId, quantity: 1 }],
  //   mode: 'payment',
  //   successUrl: `${window.location.origin}/success`,
  //   cancelUrl: `${window.location.origin}/cancel`,
  // });

  // if (error) {
  //   console.error('Error redirecting to checkout:', error);
  // }
};

export default ProductDetail;



// PRODUCT DETAIL STRIPE

// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import styled from '@emotion/styled';

// const ProductDetailContainer = styled.div`
//   display: flex;
//   padding: 20px;
// `;

// const ProductImages = styled.div`
//   flex: 1;
// `;

// const ProductInfo = styled.div`
//   flex: 1;
//   padding: 20px;
//   font-family: 'Raleway', sans-serif;
// `;

// const MainImage = styled.img`
//   width: 100%;
//   height: auto;
// `;

// function ProductDetail() {
//   const { id } = useParams();
//   const [product, setProduct] = useState(null);

//   useEffect(() => {
//     axios
//       .get('/.netlify/functions/fetch-product-details', { params: { id } })
//       .then(response => {
//         setProduct(response.data.product);
//       })
//       .catch(error => {
//         console.error('Error fetching product:', error);
//       });
//   }, [id]);

//   if (!product) {
//     return <p>Loading product details...</p>;
//   }

//   return (
//     <ProductDetailContainer>
//       <ProductImages>
//         <MainImage src={product.images[0]} alt={product.name} />
//         {/* Additional thumbnails can go here */}
//       </ProductImages>
//       <ProductInfo>
//         <h1>{product.name}</h1>
//         <p>{product.description}</p>
//         {product.price && (
//           <h2>
//             {(product.price.unit_amount / 100).toFixed(2)}{' '}
//             {product.price.currency.toUpperCase()}
//           </h2>
//         )}
//         <button onClick={() => handleBuyNow(product.price.id)}>
//           Buy Now
//         </button>
//       </ProductInfo>
//     </ProductDetailContainer>
//   );
// }

// const handleBuyNow = async (priceId) => {
//   // const stripe = await loadStripe('your-publishable-key-here');

//   // const { error } = await stripe.redirectToCheckout({
//   //   lineItems: [{ price: priceId, quantity: 1 }],
//   //   mode: 'payment',
//   //   successUrl: `${window.location.origin}/success`,
//   //   cancelUrl: `${window.location.origin}/cancel`,
//   // });

//   // if (error) {
//   //   console.error('Error redirecting to checkout:', error);
//   // }
// };

// export default ProductDetail;


// import React from 'react';
// import { useParams } from 'react-router-dom';
// import styled from '@emotion/styled';

// const ProductDetailContainer = styled.div`
//   display: flex;
//   padding: 20px;
// `;

// const ProductImages = styled.div`
//   flex: 1;
// `;

// const ProductInfo = styled.div`
//   flex: 1;
//   padding: 20px;
//   font-family: 'Raleway', sans-serif;
// `;

// const MainImage = styled.img`
//   width: 100%;
//   height: auto;
// `;

// function ProductDetail() {
//   const { id } = useParams(); // Get the dynamic :id from the URL

//   // Simulate fetching product data based on the ID
//   const product = {
//     id,
//     name: `Product ${id}`,
//     image: `/path-to-image-${id}.jpg`,
//     description: `This is the description for product ${id}.`,
//     price: `$${id * 10}`, // Example price calculation
//   };

//   return (
//     <ProductDetailContainer>
//       <ProductImages>
//         <MainImage src={product.image} alt={product.name} />
//         {/* Additional thumbnails can go here */}
//       </ProductImages>
//       <ProductInfo>
//         <h1>{product.name}</h1>
//         <p>{product.description}</p>
//         <h2>{product.price}</h2>
//         <button>Buy Now</button>
//       </ProductInfo>
//     </ProductDetailContainer>
//   );
// }

// export default ProductDetail;
