import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
// import FloatingCart from './FloatingCart';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: white;
  position: relative;

  img {
    width: 100px;
  }
`;

// const Nav = styled.nav`
//   a {
//     margin: 0 15px;
//     text-decoration: none;
//     color: black;
//     font-family: 'Raleway', sans-serif;
//   }
// `;

// const CartLink = styled.div`
//   position: relative;

//   &:hover .floating-cart {
//     display: block;
//   }
// `;

function Header({ cart, showCart, setShowCart, onCheckout }) {

  return (
    <HeaderContainer>
      <div>
        <Link to="/">
          <img src="/gragoods-logo2x.png" alt="Logo" />
        </Link>
      </div>
      {/* <Nav>
        <Link to="/">Home</Link>
        <Link to="/products">Products</Link>
        <Link to="/blog">Blog</Link>
        <Link to="/contact">Contact</Link>
      </Nav>
      <CartLink
        onMouseEnter={() => setShowCart(true)}
        onMouseLeave={() => setShowCart(false)}
      >
        <Link to="/cart">Cart ({cart.length})</Link>
        <FloatingCart
          className="floating-cart"
          cart={cart}
          show={showCart}
          onCheckout={onCheckout}
        />
      </CartLink> */}
    </HeaderContainer>
  );
}

export default Header;


// /** @jsxImportSource @emotion/react */
// import styled from '@emotion/styled';
// import { Link } from 'react-router-dom';

// const HeaderContainer = styled.header`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 20px;
//   background-color: white;
// `;

// const Nav = styled.nav`
//   a {
//     margin: 0 15px;
//     text-decoration: none;
//     color: black;
//     font-family: 'Raleway', sans-serif;
//   }
// `;

// const CartLink = styled.div`
//   font-family: 'Raleway', sans-serif;
// `;

// function Header() {
//   return (
//     <HeaderContainer>
//       <div>
//         <Link to="/">
//           <img src="/path-to-logo.png" alt="Gray Goods Logo" />
//         </Link>
//       </div>
//       <Nav>
//         <Link to="/">Home</Link>
//         <Link to="/products">Products</Link>
//         <Link to="/blog">Blog</Link>
//         <Link to="/contact">Contact</Link>
//       </Nav>
//       <CartLink>
//         <Link to="/cart">Cart</Link>
//       </CartLink>
//     </HeaderContainer>
//   );
// }

// export default Header;
