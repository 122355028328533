import React from 'react';
// import Slider from '../components/Slider';
// import ProductRow from '../components/ProductRow';
import SliderSection from '../components/SliderSection';
import ShopComingSoon from '../components/ShopComingSoon';
import InstagramFeed from '../components/InstagramFeed';

function Home() {
  return (
    <div>
      {/* Slider Section */}
      <SliderSection />
      {/* Shop Coming Soon Section */}
      <ShopComingSoon />
      {/* <ProductRow /> */}
      <InstagramFeed />
    </div>
  );
}

export default Home;
