import React from 'react';
import styled from '@emotion/styled';
import { FaTiktok, FaInstagram, FaEnvelope } from 'react-icons/fa';

const FooterContainer = styled.footer`
  background-color: #ffffff;
  padding: 40px 20px;
  text-align: center;
  border-top: 1px solid #ddd;
  font-family: 'Raleway', sans-serif;
`;

const BrandLogo = styled.div`
  margin-bottom: 20px;

  img {
    width: 80px; // Adjust as needed
    height: auto;
  }
`;

const BrandDescription = styled.p`
  color: #666;
  font-size: 16px;
  margin-bottom: 20px;
`;

const SocialMediaLinks = styled.div`
  margin-bottom: 30px;

  a {
    color: #000;
    font-size: 20px;
    margin: 0 15px;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #a07c5a; // Adjust hover color as needed
    }
  }
`;

const Copyright = styled.div`
  font-size: 14px;
  color: #999;
`;

function Footer() {
  return (
    <FooterContainer>
      <BrandLogo>
        <img src="/gragoods-logo2x.png" alt="Logo" />
      </BrandLogo>

      <BrandDescription>
        Curated Vintage Clothing & Unique Finds in DC
      </BrandDescription>

      <SocialMediaLinks>
        <a href="https://instagram.com/shop_graygoods" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
          <FaInstagram />
        </a>
        <a href="https://tiktok.com/@shopgraygoods" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
          <FaTiktok />
        </a>
        <a href="mailto:info@graygoods.co" aria-label="Email">
          <FaEnvelope />
        </a>
      </SocialMediaLinks>

      <Copyright>
        &copy; {new Date().getFullYear()} Gray Goods. All Rights Reserved.
      </Copyright>
    </FooterContainer>
  );
}

export default Footer;
