import { Global, css } from '@emotion/react';
import ITCSouvenirTtf from '../assets/fonts/Souvenir-Medium.ttf';
import ITCSouvenirWoff from '../assets/fonts/Souvenir-Medium.woff';
import ITCSouvenirWoff2 from '../assets/fonts/Souvenir-Medium.woff2';

// Create GlobalStyles using Emotion
const GlobalStyles = () => (
  <Global
    styles={css`
      /* Import Figtree from Google Fonts */
      @import url('https://fonts.googleapis.com/css2?family=Figtree:wght@400;700&display=swap');

      /* Define ITC Souvenir using @font-face */
      @font-face {
        font-family: 'ITC Souvenir';
        src: url(${ITCSouvenirWoff2}) format('woff2'),
             url(${ITCSouvenirWoff}) format('woff'),
             url(${ITCSouvenirTtf}) format('truetype');
        font-weight: normal;
        font-style: normal;
      }

      /* Global Styles */
      body {
        font-family: 'Figtree', sans-serif;
        margin: 0;
        padding: 0;
        background-color: #ffffff;
        color: #333;
      }

      h1, h2, h3, h4, h5, h6 {
        font-family: 'ITC Souvenir', serif;
        font-weight: 100;
      }
    `}
  />
);

export default GlobalStyles;
