import React from 'react';
import styled from '@emotion/styled';

const ContactContainer = styled.div`
  padding: 20px;
  font-family: 'Raleway', sans-serif;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 10px;
    font-size: 18px;
  }

  input, textarea {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Raleway', sans-serif;
  }
`;

function Contact() {
  return (
    <ContactContainer>
      <h1>Contact Us</h1>
      <ContactForm>
        <label htmlFor="name">Name</label>
        <input type="text" id="name" name="name" />

        <label htmlFor="email">Email</label>
        <input type="email" id="email" name="email" />

        <label htmlFor="message">Message</label>
        <textarea id="message" name="message" rows="6"></textarea>

        <button type="submit">Send Message</button>
      </ContactForm>
    </ContactContainer>
  );
}

export default Contact;
