import React from 'react';
import styled from '@emotion/styled';
import ComingSoonImage1 from '../images/shopcomingsoon1.png'
import ComingSoonImage2 from '../images/shopcomingsoon2.png'

const ComingSoonSection = styled.div`
  padding: 50px 20px;
  text-align: center;

  h2 {
    margin-bottom: 20px;
    font-size: 36px;
  }

  .products-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 50px;

    .product {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        height: auto;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    }

    /* #product2 {
      margin-top: 200px;
    } */
  }
`;

function ShopComingSoon() {
  return (
    <ComingSoonSection>
      <h2>Shop Coming Soon!</h2>
      <div className="products-grid">
        <div className="product">
          <img src={ComingSoonImage2} alt="Product 1" />
        </div>
        <div className="product" id="product2">
          <img src={ComingSoonImage1} alt="Product 2" />
        </div>
      </div>
    </ComingSoonSection>
  );
}

export default ShopComingSoon;
