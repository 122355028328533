import React from 'react';
import ProductGrid from '../components/ProductGrid';

function Products({ addToCart }) {
  return (
    <div>
      <h1>Products</h1>
      <ProductGrid addToCart={addToCart} />
    </div>
  );
}

export default Products;
