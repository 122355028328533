import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import styled from '@emotion/styled';
import * as amplitude from '@amplitude/analytics-browser';


import Home from './pages/Home';
import Products from './pages/Products';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Header from './components/Header';
import Footer from './components/Footer';
import axios from 'axios';
// import { loadStripe } from '@stripe/stripe-js';
import GlobalStyle from './components/GlobalStyles';
import ProductDetail from './components/ProductDetail'; // Import the ProductDetail component


// const stripePromise = loadStripe('pk_test_51PsFlBEvQCFIairhgXjw5ztvGOlevuXmp3FVswaM8T9mEB5xL20SDphVzK8yy5AZZslY5cMAtuJ9C7C9dE67LSK200sWCIFpdH');

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.main`
  flex: 1;
`;

function App() {
  const [cart, setCart] = useState([]);
  const [showCart, setShowCart] = useState(false);

  amplitude.init('9e8483eb221c4786028333e4732fa99b');

  const addToCart = (product) => {
    console.log('Adding to cart', product);
    setCart((prevCart) => {
      const existingProduct = prevCart.find(item => item.id === product.id);
      if (existingProduct) {
        return prevCart.map(item =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevCart, { ...product, quantity: 1 }];
      }
    });

    setShowCart(true);
  };
  
  // Stripe Add to Cart
  // const addToCart = (product) => {
  //   console.log('Adding to cart', product);
  //   setCart((prevCart) => {
  //     const existingProduct = prevCart.find(item => item.id === product.id);
  //     if (existingProduct) {
  //       return prevCart.map(item =>
  //         item.id === product.id
  //           ? { ...item, quantity: item.quantity + 1 }
  //           : item
  //       );
  //     } else {
  //       return [...prevCart, { ...product, quantity: 1 }];
  //     }
  //   });

  //   setShowCart(true);
  // };

  const handleCheckout = async () => {
    try {
      const response = await axios.post('/.netlify/functions/checkout-square', { cartItems: cart });

      window.location.href = response.data.paymentLinkUrl;
      // const stripe = await stripePromise;
      // const { error } = await stripe.redirectToCheckout({
      //   sessionId: response.data.id,
      // });

      // if (error) {
      //   console.error('Error redirecting to checkout:', error);
      // }
    } catch (error) {
      console.error('Checkout error:', error);
    }
  };

  return (
    <Router>
      <AppContainer>
        <GlobalStyle />
        <Header cart={cart} onCheckout={handleCheckout} showCart={showCart} setShowCart={setShowCart}/>
        <Content>
          <Routes>
            <Route exact path="/" element={<Home/>} />
            <Route path="/products" element={<Products addToCart={addToCart} />} />
            <Route path="/products/:id" element={<ProductDetail />} /> {/* Dynamic Route */}
            <Route path="/blog" element={<Blog/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route
              path="/cancel" element={<Navigate replace to="/" />}
            />
          </Routes>
        </Content>
        <Footer />
      </AppContainer>
    </Router>
  );
}

export default App;
