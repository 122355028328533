import React from 'react';
import styled from '@emotion/styled';

const BlogContainer = styled.div`
  padding: 20px;
  font-family: 'Raleway', sans-serif;
`;

const BlogPost = styled.div`
  margin-bottom: 40px;

  h2 {
    margin-bottom: 10px;
    font-size: 24px;
  }

  p {
    font-size: 18px;
    line-height: 1.6;
  }
`;

function Blog() {
  const posts = [
    { id: 1, title: 'Blog Post 1', content: 'This is the content for blog post 1.' },
    { id: 2, title: 'Blog Post 2', content: 'This is the content for blog post 2.' },
    // Add more blog posts here
  ];

  return (
    <BlogContainer>
      {posts.map(post => (
        <BlogPost key={post.id}>
          <h2>{post.title}</h2>
          <p>{post.content}</p>
        </BlogPost>
      ))}
    </BlogContainer>
  );
}

export default Blog;
