import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

const InstagramFeedSection = styled.div`
  margin: 50px 0;
  padding: 60px;
  text-align: center;
  background: #A68D64;
  height: auto;
  
  h2 {
    font-size: 36px;
    margin-bottom: 30px;
    color: white;
  }

  iframe {
    width: 100%;
    height: 600px;
    border: none;
    pointer-events: none;
    a {
      pointer-events: none;
    }
    @media (max-width: 768px) {
      height: 900px;
    }
  }
`;

const OverlayLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(255, 255, 255, 0); // Transparent overlay
`;

const WidgetContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 900px; // Set a max width to control the size of the iframe
  margin: 0 auto; // Center align the container
  height: 600px; // Match the height of the iframe
  @media (max-width: 768px) {
    height: 900px;
  }
`;

function InstagramFeed() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to determine if the user is on mobile or desktop
    const checkMobile = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };

    // Initial check
    checkMobile();

    // Add event listener to handle window resizing
    window.addEventListener('resize', checkMobile);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  return (
    <InstagramFeedSection>
      <h2>Follow Us on Instagram!</h2>
      <WidgetContainer>
        <OverlayLink
          href="https://www.instagram.com/shop_graygoods/"
          target="_blank"
          rel="noopener noreferrer"
        ></OverlayLink>
        {isMobile ? (
          <>
            <script src="https://snapwidget.com/js/snapwidget.js"></script>
            <iframe src="https://snapwidget.com/embed/1081817" class="snapwidget-widget" allowtransparency="true" frameborder="0" scrolling="no" title="My New Widget"></iframe>
          </>
        ) : (
          <>
            <script src="https://snapwidget.com/js/snapwidget.js"></script>
            <iframe src="https://snapwidget.com/embed/1081814" class="snapwidget-widget" allowtransparency="true" frameborder="0" scrolling="no" title="Posts from Instagram"></iframe>
          </>
        )}
      </WidgetContainer>
    </InstagramFeedSection>
  );
}

export default InstagramFeed;