import React from 'react';
import styled from '@emotion/styled';
import Slider1 from '../images/slider1.jpeg'

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 60vh; // Adjust as needed to fit design proportions
  overflow: hidden;
`;

const BackgroundImage = styled.div`
  background-image: url('${Slider1}'); // Replace with the image of the hanging clothes
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  box-shadow: inset 0 0 0 2000px rgb(123 103 77 / 72%);
`;

const SliderContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: white;
  text-align: center;
  @media (max-width: 768px) {
    width: 354px;
  }

  @media (max-width: 480px) {
    width: 354px;
  }

  h1 {
    font-size: 46px;
    margin-bottom: 15px;

    @media (max-width: 768px) {
      font-size: 36px; // Smaller size for tablets
    }

    @media (max-width: 480px) {
      font-size: 28px; // Smaller size for mobile devices
    }
  }

  p {
    font-size: 20px;
    line-height: 1.4;

    @media (max-width: 768px) {
      font-size: 16px; // Adjust size for tablets
    }

    @media (max-width: 480px) {
      font-size: 14px; // Adjust size for mobile devices
    }
  }
`;

function SliderSection() {
  return (
    <SliderContainer>
      {/* Background image */}
      <BackgroundImage />

      {/* Static content on top */}
      <SliderContent>
        <h1>Curated Vintage Clothing & Unique Finds in DC</h1>
        <p>
          Gray Goods curates high-quality vintage clothing and vibrant glassware/barware, featuring one-of-a-kind pieces from the 50s to Y2K. Whether you’re searching for timeless classics or something that makes a statement, our collection offers pieces that are truly unforgettable.
        </p>
      </SliderContent>
    </SliderContainer>
  );
}

export default SliderSection;
